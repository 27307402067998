.add-margin-medium {
  margin-top: 15px;
}

.cursor-pointer {
  cursor: pointer;
}

.scroll-overflow {
  max-height: 600px;
  overflow-y: scroll;
}

.monospace-font,
.preformatted,
li.definition,
li.expression {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
}

ul.unstyled {
  list-style: none;
  padding: 0;
}

li.definition,
li.expression {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
}

li.expression {
  color: #777;
}

li.expression:first-child {
  color: inherit;
}

.preformatted {
  white-space: pre;
  display: block;
  line-height: 1.43;
  page-break-inside: avoid;
  word-break: break-all;
  word-wrap: break-word;
}

.comment {
  font-style: italic;
  padding-left: 30px;
}

.highlight-function {
  text-decoration: underline 2px blue;
  -webkit-text-decoration: underline 2px blue;
}

.highlight-argument {
  text-decoration: underline 2px red;
  -webkit-text-decoration: underline 2px red;
}

.highlight-global {
  text-decoration: underline 2px lightgreen;
  -webkit-text-decoration: underline 2px lightgreen;
}

.overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100; /* Weirdly, bootstrap inputs have like z-index 3? */
  position : fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
}

.overlay-item {
  display: flex;
  flex-direction: column;
  position: relative;
}

.overlay-modal {
  min-width: 600px;
}

.spinner {
  position: relative;
  display: flex;
  justify-content: center;
  font-size: 20vh;
  color: rgba(127, 127, 127, 0.9);
  background: #4db3d7;
  border: 1px solid transparent;
  border-radius: 32px;
  padding: 24px;
}

.spin {
  animation: spin-right 2.2s infinite linear;
}

@keyframes spin-right {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}
